<template>
  <div class="page">
    <!-- 查詢 -->
    <section class="table-search">
      <el-date-picker v-model="dateToDate" type="daterange" align="center" unlink-panels range-separator="~"
        start-placeholder="创建时间" end-placeholder="创建时间" :picker-options="pickerOptions" @change="reload" clearable
        value-format="yyyy-MM-dd" class="mr15">
      </el-date-picker>
      <div class="search-L">
        <el-select v-model="tableParams.serviceStatus" placeholder="请选择状态" @change="reload" clearable>
          <el-option v-for="item in ObjToOpt(serviceStatusObj)" :key="item.value" :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <!-- <el-button type="primary" @click="onAdd()">新增</el-button> -->
      </div>
    </section>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA', color: '#000000'
    }">
      <el-table-column label="创建工单时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.addDt || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="派单时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.deliverTime || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="位置" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.serviceName || "-" }}</span>
          <span>&nbsp;-&nbsp;</span>
          <span>{{ scope.row.roomName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <span>{{
            serviceStatusObj[scope.row.serviceStatus] ||
            "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="服务人" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.serviceStatus == 2 || scope.row.serviceStatus == 3">
            暂未分配
          </template>
          <template v-else>
            <span>{{ scope.row.serviceUserName || "-" }}</span>
            <span>&nbsp;|&nbsp;</span>
            <span>{{ scope.row.serviceUserMobile || "-" }}</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="服务开始时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.serviceStartTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="服务结束时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.serviceEndTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onServe(scope.row)">查看派单内容</el-button>
          <MallOperateList :dispatchFormId="scope.row.dispatchFormId"></MallOperateList>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </section>
    <!-- 派送内容 -->
    <el-dialog title="派送内容" :visible.sync="serveShow" :close-on-click-modal="false" append-to-body width="30%">
      <el-descriptions :column="1">
        <el-descriptions-item label="派送状态">{{ serviceStatusObj[dataInfo.serviceStatus] }}</el-descriptions-item>
        <el-descriptions-item label="订单商品">{{ dataInfo.orderVo && dataInfo.orderVo.goodsInfo }}</el-descriptions-item>
        <el-descriptions-item label="订单备注">{{ dataInfo.remark }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMallOrderList,
  getMallWorkOrder
} from "@/api/serve/serve";
import { serveOrderStatusObj, serveOrderStatusColor } from "@/db/objs";
import { ObjToOpt } from "@/utils/utils";
import { pickerOptions } from "@/db/objs";
import MallOperateList from "./child/MallOperateList.vue";
import { serviceStatusObj } from "@/db/objs";
export default {
  components: { MallOperateList },
  data() {
    return {
      ObjToOpt,
      serveOrderStatusObj,
      serveOrderStatusColor,
      pickerOptions,
      serviceStatusObj,
      // table表格
      dateToDate: [],
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],//表格数据
      tableParams: {
        page: 1,
        size: 10,
        kw: "",//查询关键字
        merchantId: "",//商户ID
        shopId: "",//店铺ID
        serviceStatus: "",//状态
      },
      // 审批内容
      serveShow: false,
      dataInfo: "",//审批内容
    }
  },
  created() {
    let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getMallOrderList();// 【请求】表格数据
  },
  methods: {
    // 【请求】表格数据
    getMallOrderList() {
      if (this.dateToDate) {
        this.tableParams.startTime = this.dateToDate[0];
        this.tableParams.endTime = this.dateToDate[1];
      } else {
        this.tableParams.startTime = "";
        this.tableParams.endTime = "";
      }
      let data = this.tableParams;
      getMallOrderList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】审批内容
    getMallWorkOrder(row) {
      getMallWorkOrder(row).then((res) => {
        if (res.isSuccess == "yes") {
          this.dataInfo = res.data;
        }
      });
    },


    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getMallOrderList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getMallOrderList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },

    // 【监听】查看派单内容
    onServe(row) {
      this.serveShow = true;
      this.getMallWorkOrder(row);
    },
  }
}
</script>


<style lang="scss" scoped>
.page {
  padding: .15rem;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  // justify-content: space-between;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
}
</style>