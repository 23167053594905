import request from '@/utils/request'
import Export from '@/utils/export';

// 获取服务人员列表
export function getPeopleList(data) {
  return request('post', '/apm/advance/people/list', data)
}

// 新增服务人员
export function getPeopleAdd(data) {
  return request('post', '/apm/advance/people/create', data)
}

// 编辑服务人员
export function getPeopleEdit(data) {
  return request('post', '/apm/advance/people/update', data)
}

// 启用停用服务人员
export function getStartStopPeople(data) {
  return request('post', '/apm/advance/people/operate', data)
}

// 获取用户二维码
export function getQrCode(data) {
  return request('post', '/apm/advance/people/getQrCode', data)
}

// 获取服务工单列表
export function getPeopleOrderList(data) {
  return request('post', '/apm/advanceForm/listByPc', data)
}

// 获取服务操作记录
export function getPeopleOrderRecordList(data) {
  return request('post', '/apm/advanceForm/operateRecordByPc', data)
}

// 设置上班时间
export function getAddAdvance(data) {
  return request('post', '/apm/shop/addAdvance', data)
}

// 获取服务内容
export function getServeInfo(data) {
  return request('post', '/apm/advanceForm/serviceContentByPc', data)
}

// 商品派送工单列表
export function getMallOrderList(data) {
  return request('post', '/apm/dispatchForm/listByPc', data)
}

// 派送订单详情
export function getMallWorkOrder(data) {
  return request('get', '/apm/dispatchForm/getDispatchFormInfo?dispatchFormId=' + data.dispatchFormId)
}

// 获取派送订单操作记录
export function getMallOrderRecordList(data) {
  return request('post', '/apm/dispatchForm/operateRecordByPc', data)
}

// 业绩列表查询
export function getPerformanceList(data) {
  return request('post', '/apm/advance/performance/page', data)
}

// 业绩详情查询
export function getPerformanceDetail(data) {
  return request('post', '/apm/advance/performance/infoPage', data)
}
// 业绩商品查询
export function getGoodsList(data) {
  return request('post', '/apm/dispatch/goods/list', data)
}

// 导出业绩列表
export function goExportPerformance(data,name) {
  return Export('post', '/apm/advance/performance/excelExport', data,name)
}
